// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-document-page-templates-category-three-three-tsx": () => import("./../../../src/components/Document/PageTemplates/Category/Three/Three.tsx" /* webpackChunkName: "component---src-components-document-page-templates-category-three-three-tsx" */),
  "component---src-components-document-page-templates-preview-preview-tsx": () => import("./../../../src/components/Document/PageTemplates/Preview/Preview.tsx" /* webpackChunkName: "component---src-components-document-page-templates-preview-preview-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-admin-index-jsx": () => import("./../../../src/pages/admin/index.jsx" /* webpackChunkName: "component---src-pages-admin-index-jsx" */),
  "component---src-pages-auth-auth-0-callback-js": () => import("./../../../src/pages/auth/auth0/callback.js" /* webpackChunkName: "component---src-pages-auth-auth-0-callback-js" */),
  "component---src-pages-blog-categories-dato-cms-category-slug-js": () => import("./../../../src/pages/blog/categories/{DatoCmsCategory.slug}.js" /* webpackChunkName: "component---src-pages-blog-categories-dato-cms-category-slug-js" */),
  "component---src-pages-blog-categories-index-js": () => import("./../../../src/pages/blog/categories/index.js" /* webpackChunkName: "component---src-pages-blog-categories-index-js" */),
  "component---src-pages-blog-dato-cms-post-slug-js": () => import("./../../../src/pages/blog/{DatoCmsPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-dato-cms-post-slug-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-brainteasers-jsx": () => import("./../../../src/pages/brainteasers.jsx" /* webpackChunkName: "component---src-pages-brainteasers-jsx" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-form-form-tsx": () => import("./../../../src/pages/checkout/Form/Form.tsx" /* webpackChunkName: "component---src-pages-checkout-form-form-tsx" */),
  "component---src-pages-checkout-form-lazy-tsx": () => import("./../../../src/pages/checkout/Form/Lazy.tsx" /* webpackChunkName: "component---src-pages-checkout-form-lazy-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-document-js": () => import("./../../../src/pages/document.js" /* webpackChunkName: "component---src-pages-document-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-embeded-js": () => import("./../../../src/pages/embeded.js" /* webpackChunkName: "component---src-pages-embeded-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lesson-jsx": () => import("./../../../src/pages/lesson.jsx" /* webpackChunkName: "component---src-pages-lesson-jsx" */),
  "component---src-pages-library-js": () => import("./../../../src/pages/library.js" /* webpackChunkName: "component---src-pages-library-js" */),
  "component---src-pages-modules-js": () => import("./../../../src/pages/modules.js" /* webpackChunkName: "component---src-pages-modules-js" */),
  "component---src-pages-post-checkout-js": () => import("./../../../src/pages/post-checkout.js" /* webpackChunkName: "component---src-pages-post-checkout-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-question-bank-index-tsx": () => import("./../../../src/pages/question-bank/index.tsx" /* webpackChunkName: "component---src-pages-question-bank-index-tsx" */),
  "component---src-pages-subscriptions-js": () => import("./../../../src/pages/subscriptions.js" /* webpackChunkName: "component---src-pages-subscriptions-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-tuition-booking-js": () => import("./../../../src/pages/tuitionBooking.js" /* webpackChunkName: "component---src-pages-tuition-booking-js" */),
  "component---src-templates-brainteaser-jsx": () => import("./../../../src/templates/brainteaser.jsx" /* webpackChunkName: "component---src-templates-brainteaser-jsx" */),
  "component---src-templates-document-js": () => import("./../../../src/templates/document.js" /* webpackChunkName: "component---src-templates-document-js" */),
  "component---src-templates-game-jsx": () => import("./../../../src/templates/game.jsx" /* webpackChunkName: "component---src-templates-game-jsx" */),
  "component---src-templates-interactive-learning-co-ordinates-js": () => import("./../../../src/templates/interactive-learning/co-ordinates.js" /* webpackChunkName: "component---src-templates-interactive-learning-co-ordinates-js" */),
  "component---src-templates-interactive-learning-jsx": () => import("./../../../src/templates/interactive-learning.jsx" /* webpackChunkName: "component---src-templates-interactive-learning-jsx" */),
  "component---src-templates-interactive-learning-slope-sister-jsx": () => import("./../../../src/templates/interactive-learning/slope-sister.jsx" /* webpackChunkName: "component---src-templates-interactive-learning-slope-sister-jsx" */),
  "component---src-templates-module-js": () => import("./../../../src/templates/module.js" /* webpackChunkName: "component---src-templates-module-js" */),
  "component---src-templates-module-sub-category-documents-js": () => import("./../../../src/templates/module-sub-category-documents.js" /* webpackChunkName: "component---src-templates-module-sub-category-documents-js" */),
  "component---src-templates-module-sub-category-js": () => import("./../../../src/templates/module-sub-category.js" /* webpackChunkName: "component---src-templates-module-sub-category-js" */),
  "component---src-templates-module-sub-category-sub-category-options-documents-level-2-js": () => import("./../../../src/templates/module-sub-category-sub-category-options-documents-level2.js" /* webpackChunkName: "component---src-templates-module-sub-category-sub-category-options-documents-level-2-js" */),
  "component---src-templates-module-sub-category-sub-category-options-js": () => import("./../../../src/templates/module-sub-category-sub-category-options.js" /* webpackChunkName: "component---src-templates-module-sub-category-sub-category-options-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-question-bank-page-js": () => import("./../../../src/templates/question-bank/page.js" /* webpackChunkName: "component---src-templates-question-bank-page-js" */)
}

