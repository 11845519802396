import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '@src/utils/mmc-api/api.js';

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const user = await api.me();
  return user;
});

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: null,
    isLoading: true,
  },
  reducers: {
    update: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    updateLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.data = {
        ...action.payload,
      };
      state.isLoading = false;
    });
  },
});

export const updateDetails = (payload) => (dispatch) => {
  dispatch(userSlice.actions.update(payload));
};

export default userSlice.reducer;
