const API_URL = process.env.GATSBY_MMC_API_URL; //Strapi instance

function getHeaders() {
  let headers = {
    'Content-Type': 'application/json',
  };

  const jwt = localStorage.getItem('jwt');
  if (jwt) {
    headers.Authorization = `Bearer ${jwt}`;
  }
  return headers;
}

async function get(url) {
  const headers = getHeaders();
  try {
    const response = await fetch(`${API_URL}/${url}`, { headers });
    if (response.status !== 200) return null;

    return await response.json();
  } catch (error) {
    return null;
  }
}

async function fetchWithBody(method, url, data) {
  method = method.toUpperCase();
  const headers = getHeaders();

  try {
    const response = await fetch(`${API_URL}/${url}`, {
      headers,
      method,
      body: JSON.stringify(data),
    });
    if (response.status !== 200) return null;

    return await response.json();
  } catch (error) {
    return null;
  }
}

async function fetchWithError(method, url, data) {
  method = method.toUpperCase();
  const headers = getHeaders();

  const response = await fetch(`${API_URL}/${url}`, {
    headers,
    method,
    body: JSON.stringify(data),
  });

  return response.json();
}

const post = async (url, data) => fetchWithBody('post', url, data);
const patch = async (url, data) => fetchWithBody('patch', url, data);
const put = async (url, data) => fetchWithBody('put', url, data);
const del = async (url, data) => fetchWithBody('delete', url, data);

export const me = () => get('users/me');
export const get_sub_end_date = () => get('users/sub-end-date');
export const updateMe = (data) => put(`users-permissions/users/me`, { data });
export const users = () => get('bookings/users');

export const createWorksheet = (data) => fetchWithError('post', 'worksheet/create', { data });
export const questionBankMe = () => get('question-bank/me');

export const miniCart = () => get('carts/mini');
export const fullCart = () => get('carts/full');
export const guestCart = (data) => post('carts/guest', { data });
export const addToCart = (id) => patch('carts/items/add', { id });
export const removeFromCart = (id) => patch('carts/items/remove', { id });
export const clearCart = () => patch('carts/items/clear');

export const addToOwned = (id) => post('owned-product/create', { id });
export const removeFromOwned = (id) => patch('owned-product/delete', { id });
export const ownedProducts = () => get('owned-product');
export const getDocument = (id) => get(`documents?id=${id}`);

export const generateDatoDocumentsData = () => get(`custom-endpoints/generate-dato-documents`);
export const getAllSubscription = (pageSize = 8, pageNum = 1, filter = {}) => {
  let url = `subscriptions/all?pageSize=${pageSize}&pageNum=${pageNum}`;
  if (filter?.type && filter?.value) {
    url += `&type=${filter.type}&filterValue=${filter.value}`;
  }
  return get(url);
};
export const getAllPurchases = (pageSize = 8, pageNum = 1, filter = {}) => {
  let url = `owned-product/all?pageSize=${pageSize}&pageNum=${pageNum}`;

  if (filter?.type && filter?.value) {
    url += `&type=${filter.type}&filterValue=${filter.value}`;
  }
  return get(url);
};
export const getFilterOptions = () => get('owned-product/options');

export const stripePay = () => post('stripe/pay').then((body) => body.clientSecret);
export const guestStripePay = (data) =>
  post('stripe/guestpay', { data }).then((body) => body.clientSecret);
export const getPaymentIntent = (id) => post('payment-intent', { id });
export const sendEmail = (data) => post('emails/emailSend', { data });
export const sendNewsletter = (data) => post('emails/sendNewsletter', { data });
export const sendContact = (data) => post('emails/sendContact', { data });

export const addAvailableDate = (data) => post('bookings/add', { data });
export const removeAvailableDate = (id) => del(`bookings/${id}`);
export const allocateDate = (data) => put('bookings/allocate', { data }); // add user to available date
export const unAllocateDate = (id) => put('bookings/unallocate', { id });
export const getAvailableDates = (startDate) => get(`bookings/date?date=${startDate}`);

export const getLessonAuthToken = () => get('custom-endpoints/generate-lesson-auth');

export const getCommentsForPost = (post_id) => get(`comments/${post_id}`);
export const createComment = (data) => post(`comments`, data);
export const replyToComment = (data) => post('comments/reply', data);

export const getChallengeQuestions = () => get('challenge-questions');
export const getChallengeQuestionsLeaderboard = () => get('challenge-questions/leaderboard');
export const submitChallengeQuestionSubmission = async (data) => {
  const headers = {};

  const jwt = localStorage.getItem('jwt');
  if (jwt) {
    headers.Authorization = `Bearer ${jwt}`;
  }

  const response = await fetch(`${API_URL}/challenge-questions-responses`, {
    headers,
    method: 'POST',
    body: data,
  });
  if (response.status !== 200) return null;

  return await response.json();
};
export const markSubmission = (data) => post('challenge-questions-responses/mark', { data });

export const getSubmissions = async () => get('challenge-questions-responses');

export const toggleQuestionOpen = async (data) => post('challenge-questions/toggle', { data });

export const newsletterSignUp = (data) => post('newsletter-sign-ups/sign-up', data);

export const getLeaderboardData = async (game) => get(`leaderboards/${game}`);

export const submitScore = async (data) => post('leaderboards', { data });
